
import _ from "lodash"
import { mapMutations, mapState } from "vuex"
import ep from "~/api/endpoint"

export default {
  props: {
    profile_data: {
      type: Object,
      default: {},
      required: false,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    is_profile: {
      type: Boolean,
      default: true,
      required: true,
    },
  },
  data() {
    return {
      current_role: "",
      dialog_visible: false,
      fullscreen_loading: false,
      is_submit: false,
      deactive_confirmation: false,
      toggle_edit_member: true,
      toggle_edit_contact: true,
      show_remove_member_confirm_dialog: false,
      member: {
        _id: "",
        role: "",
        phone: "",
        email: "",
        image_url: "",
        first_name: "",
        last_name: "",
        employee_id: "",
        display_name: "",
        teams: [],
      },
      selected_team: [],
      join_teams: [],
      leave_teams: [],
      set_selected_team: [],
      original_member: null,
      changes_input_count: 0,
      is_disable_contact: false,
      is_disable_member: false,
      is_disable_img: false,
      is_disable_deactive: false,
    }
  },
  computed: {
    ...mapState({
      owners: (state) => state.owners,
      business: (state) => state.business || {},
      oho_member: (state) => state.oho_member.member,
    }),
    display_name() {
      return _.get(this.profile_data, "display_name")
    },
    online_status() {
      return _.get(this.profile_data, "online_status")
    },
    employee_id() {
      return _.get(this.profile_data, "employee_id")
    },
    created_at() {
      return _.get(this.profile_data, "created_at")
    },
    image_url() {
      return _.get(this.profile_data, "image_url")
    },
    _id() {
      return _.get(this.profile_data, "_id")
    },
    teams() {
      return _.get(this.profile_data, "teams")
    },
    default_team_data() {
      return this.member.teams?.find((team) => team.type === "default") || {}
    },
    user_email() {
      return _.get(this.profile_data, "user_id.email")
    },
    label_value_body() {
      return [
        { label: "ชื่อในธุรกิจ", value: this.member.display_name },
        {
          label: "ชื่อ-นามสกุล",
          value:
            (this.member.first_name || "") +
            " " +
            (this.member.last_name || ""),
        },
        { label: "รหัสพนักงาน", value: this.member?.employee_id },
        {
          label: "บทบาท",
          value: this.$role.roleDisplayName(this.member.role),
        },
      ]
    },
    form_item_body() {
      return [
        {
          label: "ชื่อในธุรกิจ",
          model: "display_name",
          disabled: !this.is_profile,
          data_id: "global.popup-profile.text-input.display-name",
        },
        {
          label: "ชื่อ",
          model: "first_name",
          disabled: !this.is_profile,
          data_id: "global.popup-profile.text-input.first-name",
        },
        {
          label: "นามสกุล",
          model: "last_name",
          disabled: !this.is_profile,
          data_id: "global.popup-profile.text-input.last-name",
        },
        {
          label: "รหัสพนักงาน",
          model: "employee_id",
          disabled: !this.handleUpdateEmployeeIdPermission(),
          data_id: "global.popup-profile.text-input.employee-id",
        },
      ]
    },
    label_value_bottom() {
      return [
        { label: "เบอร์โทร", value: this.member.phone },
        { label: "อีเมล", value: this.user_email },
      ]
    },
    form_item_bottom() {
      return [
        {
          label: "เบอร์โทร",
          model: "phone",
          disabled: !this.is_profile,
          data_id: "global.popup-profile.text-input.phone",
        },
        {
          label: "อีเมล",
          placeholder: this.user_email,
          disabled: true,
        },
      ]
    },
    deactivate_member_permission() {
      if (this.is_profile) {
        return this.$permission.validate("member.self.remove")
      } else {
        const member_role = _.get(this.oho_member, "role", "")
        return this.$permission.validate(
          `member.role.level-${member_role}.remove`
        )
      }
    },
    is_warn_remove_owner() {
      return this.owners <= 1 && this.current_role === "owner"
    },
    update_employee_id_permission() {
      return this.$permission.validate("member.employee-id.update")
    },
  },
  watch: {
    set_selected_team: {
      handler(new_value) {
        if (
          (_.isEqual(this.selected_team, new_value) &&
            this.changes_input_count > 0) ||
          !_.isEqual(this.selected_team, new_value)
        ) {
          this.changes_input_count++
        }
      },
      deep: true,
    },
    member: {
      handler(new_val) {
        const is_equal = _.isEqual(new_val, this.original_member)
        if (!is_equal) {
          this.changes_input_count++
        } else if (is_equal) {
          this.changes_input_count = 0
        }
      },
      deep: true,
    },
    visible: {
      handler(new_val) {
        this.dialog_visible = new_val
      },
      immediate: true,
    },
    profile_data: {
      handler(new_value) {
        if (new_value) {
          this.updateMember(new_value)
          this.defaultMember(new_value)
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapMutations(["removeTeamFromMember", "setOhoMemberRolePermission"]),
    defaultMember() {
      const member = {
        ...this.member,
        ...this.profile_data,
      }
      this.original_member = member
    },
    updateMember(data) {
      const member = {
        ...this.member,
        ...data,
        image_url: _.get(data, "image_url"),
        display_name: _.get(data, "display_name"),
        teams: _.get(data, "teams"),
      }
      this.member = member
      this.selected_team = _.get(data, "teams")
      this.set_selected_team = this.selected_team
      this.current_role = _.get(data, "role") || ""
    },
    async onSubmit() {
      const { role, ...data } = _.pick(this.member, [
        "image_url",
        "employee_id",
        "display_name",
        "role",
        "phone",
        "email",
        "first_name",
        "last_name",
        // "teams"
        // Member email is deprecated. Read more: https://www.notion.so/bmlpu/TK-0888-db9cf5cbfffc46de9dc6f1f68db0ef9a
      ])
      const endpoint = this.is_profile
        ? `${ep.member}/me`
        : `${ep.member}/${this._id}`

      try {
        this.fullscreen_loading = true

        const createRequestBody = () => {
          const commonData = {
            ...data,
            join_teams: this.join_teams,
            leave_teams: this.leave_teams,
          }

          const role_excluded = ["sale", "staff", "agent"]
          const is_role_changed = this.member.role == this.original_member.role
          const is_role_exclude = role_excluded.includes(this.oho_member.role)

          if (is_role_changed) {
            return commonData
          }

          if (is_role_exclude) {
            return { ...data }
          }

          return {
            ...commonData,
            role: this.member.role,
          }
        }
        const body = createRequestBody()
        const res = await this.$ohoMemberApi.$patch(endpoint, body, {
          params: {
            "$populate[path]": "role_permission",
          },
        })

        if (res) {
          this.is_submit = true
          this.original_member = { ...res }
          this.selected_team = this.set_selected_team
          this.join_teams = []
          this.leave_teams = []

          if (this.is_profile) {
            const permission = _.get(res, "role_permission")

            if (permission) {
              this.setOhoMemberRolePermission(permission)
            }
            if (this.leave_teams.length > 0) {
              this.removeTeamFromMember(this.leave_teams)
            }
          }

          this.resetDisableButton()
          this.$message({
            message: "บันทึกเรียบร้อย",
            type: "success",
          })
        }
      } catch (error) {
        this.$message.error(_.get(error, "response.data"))
      }

      this.fullscreen_loading = false
    },
    async uploadFile(raw) {
      if (!this.fileSizeValid(raw) && !this.imageTypeValid(raw)) return
      const res = await this.handleUploadImage(raw)
      if (res) {
        this.member.image_url = _.get(res, "url")
        this.onSubmit()
      }
    },
    imageTypeValid(raw) {
      const types = ["image/jpeg", "image/jpg", "image/png", "image/gif"]
      if (!types.includes(raw.type)) {
        this.$message.error("File must be image format!")
      }
      return types.includes(raw.type)
    },
    fileSizeValid(raw) {
      const isLt2M = raw.size / 1024 / 1024 < 10
      if (!isLt2M) {
        this.$message.error("File size can not exceed 10MB!")
      }
      return isLt2M
    },
    async handleUploadImage(raw) {
      try {
        const form_data = new FormData()
        form_data.append("file", raw)
        return await this.$ohoMemberApi.$post(`${ep.file}/`, form_data, {
          headers: { "content-type": "multipart/form-data" },
        })
      } catch (error) {
        // this.$message.error(_.get(error, "response.data"))
      }
    },
    async deactivateMe() {
      try {
        await this.$ohoMemberApi.$patch(`${ep.deactivate_member}/me`)
        this.$router.replace("/select-business")
      } catch (error) {
        this.$logger.error(_.get(error, "response.data"))
      }
    },
    async deactivateMember() {
      try {
        this.fullscreen_loading = true
        await this.$ohoMemberApi.$patch(`${ep.deactivate_member}/${this._id}`)
        this.updateMember(this.profile_data)
        this.$message({
          message: "ลบเรียบร้อย",
          type: "success",
        })
        location.reload()
      } catch (error) {
        this.fullscreen_loading = false
        this.$message.error(_.get(error, "response.statusText"))
      }
      this.edit_member_dialog_visible = false
    },
    resetMember() {
      return { ...this.original_member, created_at: this.member.created_at }
    },
    resetDisableButton() {
      this.toggle_edit_member = true
      this.toggle_edit_contact = true
      this.is_disable_member = false
      this.is_disable_contact = false
      this.is_disable_deactive = false
      this.is_disable_img = false
    },
    toggleEditMember() {
      this.toggle_edit_member = !this.toggle_edit_member
      this.is_disable_contact = !this.is_disable_contact
      this.is_disable_deactive = !this.is_disable_deactive
      this.is_disable_img = !this.is_disable_img
      if (this.toggle_edit_member) {
        this.member = this.resetMember()
        this.changes_input_count = 0
        // when cancel edit will reset to original data
        this.set_selected_team = this.selected_team
        this.join_teams = []
        this.leave_teams = []
      }
    },
    toggleEditContact() {
      this.toggle_edit_contact = !this.toggle_edit_contact
      this.is_disable_member = !this.is_disable_member
      this.is_disable_deactive = !this.is_disable_deactive
      this.is_disable_img = !this.is_disable_img
      if (this.toggle_edit_contact) {
        this.member = this.resetMember()
        this.changes_input_count = 0
      }
    },
    handleBeforeDialogClose() {
      this.member = {}
      this.$emit("update:visible", false)
      this.resetDisableButton()
      if (this.is_submit && this.changes_input_count > 0) {
        location.reload()
      }
    },
    getOnlineStatusClassname(status) {
      if (status === "online") {
        return "is-online"
      } else if (status === "away") {
        return "is-away"
      } else {
        return "is-offline"
      }
    },
    handleUpdateJoinTeams(updated_join_teams) {
      this.join_teams = updated_join_teams
    },
    handleUpdateLeaveTeams(updated_leave_teams) {
      this.leave_teams = updated_leave_teams
    },
    handleUpdateSelectedTeams(update_set_selected_teams) {
      this.set_selected_team = update_set_selected_teams
    },
    closeRemoveMemberConfirmDialog() {
      this.show_remove_member_confirm_dialog = false
    },
    openRemoveMemberConfirmDialog() {
      this.show_remove_member_confirm_dialog = true
    },
    handleRemoveButtonClick() {
      if (this.is_profile) {
        this.deactive_confirmation = true
      } else {
        this.show_remove_member_confirm_dialog = true
      }
    },
    handleUpdateProfilePermission() {
      const member_id = _.get(this.original_member, "_id")
      const member_role = _.get(this.original_member, "role")
      const current_member_id = _.get(this.oho_member, "_id", "")

      if (member_id === current_member_id) {
        return true
      } else {
        return this.$permission.validate(
          `member.profile.level-${member_role}.update`
        )
      }
    },
    handleUpdateEmployeeIdPermission() {
      const member_id = _.get(this.original_member, "_id")
      const member_role = _.get(this.original_member, "role")
      const current_member_id = _.get(this.oho_member, "_id", "")

      if (member_id === current_member_id) {
        return true
      } else {
        return this.$permission.validate(
          `member.employee-id.level-${member_role}.update`
        )
      }
    },
    submitUserSettingForm() {
      const isFormValid = this.$refs.memberProfileEditForm.validateForm()
      if (isFormValid) {
        this.onSubmit()
      }
    },
  },
}
