import { render, staticRenderFns } from "./ProfileModal.vue?vue&type=template&id=48a0312c&scoped=true&"
import script from "./ProfileModal.vue?vue&type=script&lang=js&"
export * from "./ProfileModal.vue?vue&type=script&lang=js&"
import style0 from "./ProfileModal.vue?vue&type=style&index=0&id=48a0312c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48a0312c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProfileImage: require('/builds/oho/oho-web-app/components/ProfileImage.vue').default,TextOverflow: require('/builds/oho/oho-web-app/components/TextOverflow.vue').default,BusinessImage: require('/builds/oho/oho-web-app/components/BusinessImage.vue').default,MemberProfileLabel: require('/builds/oho/oho-web-app/components/Member/ProfileLabel.vue').default,MemberProfileEditForm: require('/builds/oho/oho-web-app/components/Member/ProfileEditForm.vue').default,TeamTag: require('/builds/oho/oho-web-app/components/Team/Tag.vue').default})
