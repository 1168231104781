import _ from "lodash"
import ep from "~/api/endpoint"
import axios from "axios"

class FileService {
  async getSignedURL(file_name) {
    try {
      const res = await $nuxt.$ohoMemberApi.$post(ep.file_upload_link, {
        name: file_name,
      })

      return res
    } catch (error) {
      $nuxt.$logger.error(error)
      throw new Error(
        _.get(error, "response.data") || "ขอ Signed URL ไม่สำเร็จ"
      )
    }
  }

  async uploadFileWithSignedURL(signed_url, raw_file) {
    try {
      /**
       * avoid to reuse $axios.$put because of when user login with facebook account
       * then the system automatically set default "Authorization" Bearer to header
       * that face to CORS error when uploading file with Signed URL
       */
      const res = await axios.put(signed_url, raw_file, {
        headers: {
          "x-goog-acl": "public-read",
          "Content-Type": raw_file.type,
          "x-goog-meta-method": "signed_url",
        },
      })

      return res
    } catch (error) {
      $nuxt.$logger.error(error)
      throw new Error(_.get(error, "response.data") || "อัพโหลดไฟล์ไม่สำเร็จ")
    }
  }

  async upload(file) {
    try {
      const raw_file = _.get(file, "raw")
      const file_name = _.get(raw_file, "name")
      const uploaded_file = await this.getSignedURL(file_name)

      if (uploaded_file) {
        const signed_url = _.get(uploaded_file, "upload_link.signed_url")
        await this.uploadFileWithSignedURL(signed_url, raw_file)

        return uploaded_file
      }
    } catch (error) {
      $nuxt.$logger.error(error)
      throw new Error(_.get(error, "response.data") || "อัพโหลดไฟล์ไม่สำเร็จ")
    }
  }
}

export default FileService
